import { API_URL } from "../../utils/constants";
import httpClientHelper from "../../utils/httpClientHelper";
import {
  ADD_EXTRA_CLIENT_SPINNER_DIS,
  ADD_EXTRA_CLIENT_SPINNER_EN,
  ADD_EXTRA_CLIENT_SUCCESS,
  ADD_SWITCH_ID_TO_LOADER,
  DELETE_EXTRA_CLIENT_SPINNER_DIS,
  DELETE_EXTRA_CLIENT_SPINNER_EN,
  DELETE_EXTRA_CLIENT_SUCCESS,
  GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS,
  GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_EN,
  GET_CLIENT_EXTRA_COMMANDE_INVOICE_SUCCESS,
  GET_EXTRA_CLIENT_SPINNER_DIS,
  GET_EXTRA_CLIENT_SPINNER_EN,
  GET_EXTRA_CLIENT_SUCCESS,
  MODAL_CLOSE_ADD_CLIENT_EXTRA,
  REMOVE_SWITCH_ID_FROM_LOADER,
  RESTORE_EXTRA_CLIENT_SPINNER_DIS,
  RESTORE_EXTRA_CLIENT_SPINNER_EN,
  RESTORE_EXTRA_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_LOADER_DIS,
  UPDATE_LOADER_EN,
  UPDATE_MODAL_CLOSE,
} from "../types";
// import axios from "axios";
// import { toast } from "react-toastify";
import { notification } from "antd";

export const deleteClientExtra = (id) => async (dispatch) => {
  dispatch({
    type: ADD_SWITCH_ID_TO_LOADER,
    id,
  });
  dispatch({
    type: DELETE_EXTRA_CLIENT_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.xdelete(
      `${API_URL}/api/deleteClientSup/${id}`
    );
    // console.log(res?.data);
    dispatch({
      type: DELETE_EXTRA_CLIENT_SUCCESS,
      id: id,
      payload: res?.data.data,
    });
    dispatch({
      type: REMOVE_SWITCH_ID_FROM_LOADER,
      id,
    });
    dispatch({
      type: DELETE_EXTRA_CLIENT_SPINNER_DIS,
    });
    notification.success({ message: "client extra en mode inactive" });
  } catch (err) {
    // console.log(err.message);
    if (err?.response) {
      notification.error({
        message: "Impossible de supprimer un client extra pour ce moment",
      });
      dispatch({
        type: DELETE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: DELETE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: DELETE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
      notification.error({
        message: "Impossible  de supprimer client extra pour ce moment",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: DELETE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
      notification.error({
        message: "Impossible  de supprimer client extra pour ce moment",
      });
    }
  }
};

export const restoreClientExtra = (id) => async (dispatch) => {
  dispatch({
    type: ADD_SWITCH_ID_TO_LOADER,
    id,
  });
  dispatch({
    type: RESTORE_EXTRA_CLIENT_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.put(
      `${API_URL}/api/restoreClientSup/${id}`
    );
    // console.log(res?.data);
    dispatch({
      type: RESTORE_EXTRA_CLIENT_SUCCESS,
      payload: res?.data.data,
      id: id,
    });
    dispatch({
      type: RESTORE_EXTRA_CLIENT_SPINNER_DIS,
    });
    dispatch({
      type: REMOVE_SWITCH_ID_FROM_LOADER,
      id,
    });
    notification.success({ message: "Client extra en mode actif" });
  } catch (err) {
    // console.log(err.message);
    if (err?.response) {
      notification.error({
        message: "Impossible de restorer un client extra pour ce moment",
      });
      dispatch({
        type: RESTORE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: RESTORE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: RESTORE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
      notification.error({
        message: "Impossible  de restorer client extra pour ce moment",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: RESTORE_EXTRA_CLIENT_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
      notification.error({
        message: "Impossible  de restorer client extra pour ce moment",
      });
    }
  }
};

export const getClientExtra =
  (search = "") =>
  async (dispatch) => {
    dispatch({
      type: GET_EXTRA_CLIENT_SPINNER_EN,
    });
    try {
      const res = await httpClientHelper.get(
        `${API_URL}/api/clientsup?${search === "" ? "" : `search=${search}`}`
      );
      dispatch({
        type: GET_EXTRA_CLIENT_SUCCESS,
        payload: res?.data.data,
      });
      dispatch({
        type: GET_EXTRA_CLIENT_SPINNER_DIS,
      });
    } catch (err) {
      // console.log(err.message);
      if (err?.response) {
        notification.error({
          message: "Impossible de récupérer les clients Supplémentaires",
        });
        dispatch({
          type: GET_EXTRA_CLIENT_SPINNER_DIS,
        });
      } else if (err.response?.status === 400) {
        notification.error({ message: err?.response?.data?.message });
        dispatch({
          type: GET_EXTRA_CLIENT_SPINNER_DIS,
        });
      } else if (err.response?.status === 401) {
        dispatch({
          type: GET_EXTRA_CLIENT_SPINNER_DIS,
        });
        notification.error({
          message: "Impossible de récupérer les clients Supplémentaires",
        });
      } else if (err.response?.status === 500) {
        dispatch({
          type: GET_EXTRA_CLIENT_SPINNER_DIS,
        });
        notification.error({
          message: "Impossible de récupérer les clients Supplémentaires",
        });
      }
    }
  };

export const getClientExtraInvoice =
  (id = "") =>
  async (dispatch) => {
    dispatch({
      type: GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_EN,
    });
    try {
      const res = await httpClientHelper.get(
        `${API_URL}/api/clientExtraCommande/${id}`
      );
      dispatch({
        type: GET_CLIENT_EXTRA_COMMANDE_INVOICE_SUCCESS,
        payload: res?.data.data,
      });
      dispatch({
        type: GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS,
      });
    } catch (err) {
      // console.log(err.message);
      if (err?.response) {
        notification.error({
          message: "Impossible de récupérer commandes extra facture",
        });
        dispatch({
          type: GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS,
        });
      } else if (err.response?.status === 400) {
        notification.error({ message: err?.response?.data?.message });
        dispatch({
          type: GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS,
        });
      } else if (err.response?.status === 401) {
        dispatch({
          type: GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS,
        });
        notification.error({
          message: "Impossible de récupérer les commandes extra facture",
        });
      } else if (err.response?.status === 500) {
        dispatch({
          type: GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS,
        });
        notification.error({
          message: "Impossible de récupérer les commandes extra facture",
        });
      }
    }
  };

export const addClientExtra = (body, reset) => async (dispatch) => {
  dispatch({
    type: ADD_EXTRA_CLIENT_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.post(
      `${API_URL}/api/createClientsup`,
      body
    );
    // console.log(res?.data);
    dispatch({
      type: ADD_EXTRA_CLIENT_SUCCESS,
      payload: res?.data.data,
    });
    dispatch({
      type: MODAL_CLOSE_ADD_CLIENT_EXTRA,
    });
    dispatch({
      type: ADD_EXTRA_CLIENT_SPINNER_DIS,
    });
    notification.success({
      message: "clients supplémentaires ajoutée avec succées.",
    });
    reset({
      adresse: "",
      boutique: "",
      email: "",
      fullname: "",
      phone: "",
      description: "",
    });
  } catch (err) {
    // console.log(err.message);
    if (err?.response) {
      notification.error({
        message: "Impossible d'ajoutée un client supp pour ce moment",
      });
      dispatch({
        type: ADD_EXTRA_CLIENT_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: ADD_EXTRA_CLIENT_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: ADD_EXTRA_CLIENT_SPINNER_DIS,
      });
      notification.error({
        message:
          "Impossible d'ajoutée un client supplémentaires pour ce moment",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: ADD_EXTRA_CLIENT_SPINNER_DIS,
      });
      notification.error({
        message:
          "Impossible d'ajoutée un client supplémentaires pour ce moment",
      });
    }
  }
};

export const updateClientSupp =
  (body, clientSuppId, reset) => async (dispatch) => {
    dispatch({
      type: UPDATE_LOADER_EN,
    });
    try {
      const res = await httpClientHelper.put(
        `${API_URL}/api/updateClientSup/${clientSuppId}`,
        body
      );
      dispatch({
        type: UPDATE_CLIENT_SUCCESS,
        payload: {
          client: res?.data.data,
          clientSuppId,
        },
      });
      dispatch({
        type: UPDATE_MODAL_CLOSE,
      });
      dispatch({
        type: UPDATE_LOADER_DIS,
      });
      notification.success({
        message: "client supplémentaires mis a jour avec succées.",
      });
      reset({
        adresse: "",
        boutique: "",
        email: "",
        fullname: "",
        phone: "",
        description: "",
      });
    } catch (err) {
      // console.log(err.message);
      if (err?.response) {
        notification.error({
          message:
            "Impossible de mettre a jour client supplémentaires pour ce moment",
        });
        dispatch({
          type: UPDATE_LOADER_DIS,
        });
      } else if (err.response?.status === 400) {
        notification.error({ message: err?.response?.data?.message });
        dispatch({
          type: UPDATE_LOADER_DIS,
        });
      } else if (err.response?.status === 401) {
        dispatch({
          type: UPDATE_LOADER_DIS,
        });
        notification.error({
          message:
            "Impossible de mettre a jour client supplémentaires pour ce moment",
        });
      } else if (err.response?.status === 500) {
        dispatch({
          type: UPDATE_LOADER_DIS,
        });
        notification.error({
          message:
            "Impossible de mettre a jour client supplémentaires pour ce moment",
        });
      }
    }
  };
