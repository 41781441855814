import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "antd";
import FeatherIcon from "feather-icons-react";
// import { useSelector } from "react-redux";
import {
  InvoiceHeader,
  InvoiceLetterBox,
  InvoiceAction,
  ProductTable,
  OrderSummary,
} from "./styles";
import { Cards } from "../../components/cards/frame/cards-frame";
import Heading from "../../components/heading/HeadingText";
import { Button } from "../../components/Button/Buttons";

function Invoice({ facture, isExtra = false }) {
  const [invoiceTableData, setInvoiceTableData] = useState([]);

  useEffect(() => {
    if (facture?.products) {
      const newInvoiceData = facture.products.map((item, index) => ({
        key: index + 1,
        row: index + 1,
        details: (
          <>
            <div className="product-info">
              <Heading className="product-info-title" as="h6">
                {item?.product?.name}
                <span
                  style={{ marginLeft: 10, fontSize: 10, color: "#a1a1a1" }}
                >
                  {item?.product?.refv}
                </span>
              </Heading>
              <ul className="info-list">
                {item?.product?.combinations?.map(
                  (combination, combinationIndex) =>
                    Object.keys(combination).map((key, keyIndex) => (
                      <li key={`${combinationIndex}-${keyIndex}`}>
                        <span
                          className="info-title"
                          style={{ textTransform: "capitalize" }}
                        >
                          {key} :
                        </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {combination[key]}
                        </span>
                      </li>
                    ))
                )}
              </ul>
            </div>
          </>
        ),
        unit: (
          <>
            {item?.hasPack ? (
              Object.keys(item?.usedPacks)
                .filter((key) => item?.usedPacks[key].count > 0)
                .map((key, index) => (
                  <div key={`unit_price_${index}`}>
                    {key === "NOPACK" && item?.usedPacks[key]?.hasPromotion ? (
                      <span
                        style={{
                          paddingLeft: 0,
                        }}
                        className="product-unit"
                      >
                        {Number(item?.usedPacks[key]?.unitPrice)?.toFixed(3)}
                        &nbsp;TND
                      </span>
                    ) : (
                      <div
                        style={{
                          paddingLeft: 0,
                        }}
                        className="product-unit"
                      >
                        {item?.usedPacks[key]?.unitPrice?.toFixed(3)}&nbsp;TND
                        <br />
                        <span style={{ textAlign: "center", fontSize: 11 }}>
                          PACK(<b>{item?.usedPacks[key]?.count}</b>)
                        </span>
                      </div>
                    )}
                  </div>
                ))
            ) : item?.product?.hasPromotion ? (
              <span
                style={{
                  paddingLeft: 0,
                }}
                className="product-unit"
              >
                {Number(item?.product?.promotionalPrice)?.toFixed(3)}&nbsp;TND
              </span>
            ) : (
              <span
                style={{
                  paddingLeft: 0,
                }}
                className="product-unit"
              >
                {item?.product?.price?.toFixed(3)}&nbsp;TND
              </span>
            )}
          </>
          //   <div>
          //     <span
          //       className="product-unit"
          //       style={{
          //         paddingLeft: 0,
          //         textDecoration:
          //           item?.product?.hasPromotion === 1 ? "line-through" : "auto",
          //         // fontWeight: item?.product?.hasPromotion === 1 ? "700" : "500",
          //         color:
          //           item?.product?.hasPromotion === 1
          //             ? "rgb(183 50 50)"
          //             : "black",
          //       }}
          //     >
          //       {Number(item?.product?.price)?.toFixed(3)} TND
          //     </span>
          //     {item?.product?.hasPromotion === 1 && (
          //       <span className="product-unit" style={{ marginLeft: 4 }}>
          //         {item?.product?.promotionalPrice} TND
          //       </span>
          //     )}
          //   </div>
        ),
        quantity: <span className="product-quantity">{item?.quantity}</span>,
        total: (
          <span className="product-total-price">
            {item?.hasPack
              ? item?.usedPacks?.TOTAL_PRICE?.toFixed(3)
              : item?.product?.hasPromotion
              ? (
                  Number(item?.quantity) *
                  Number(item?.product?.promotionalPrice)
                ).toFixed(3)
              : (Number(item?.quantity) * Number(item?.product?.price)).toFixed(
                  3
                )}
            &nbsp;TND
          </span>
        ),
      }));
      setInvoiceTableData(newInvoiceData);
    }
  }, [facture]);

  //   const invoiceTableData = [
  //     {
  //       key: "1",
  //       row: "1",
  //       details: (
  //         <>
  //           <div className="product-info">
  //             <Heading className="product-info-title" as="h6">
  //               Fiber Base Chair
  //             </Heading>
  //             <ul className="info-list">
  //               <li>
  //                 <span className="info-title">Size :</span>
  //                 <span>Large</span>
  //               </li>
  //               <li>
  //                 <span className="info-title"> Color :</span>
  //                 <span>Brown</span>
  //               </li>
  //             </ul>
  //           </div>
  //         </>
  //       ),
  //       unit: <span className="product-unit">$248.66</span>,
  //       quantity: <span className="product-quantity">3</span>,
  //       total: <span className="product-total-price">$943.30</span>,
  //     },
  //     {
  //       key: "2",
  //       row: "2",
  //       details: (
  //         <>
  //           <div className="product-info">
  //             <Heading className="product-info-title" as="h6">
  //               Panton Tunior Chair
  //             </Heading>
  //             <ul className="info-list">
  //               <li>
  //                 <span className="info-title">Size :</span>
  //                 <span>Large</span>
  //               </li>
  //               <li>
  //                 <span className="info-title"> Color :</span>
  //                 <span>Brown</span>
  //               </li>
  //             </ul>
  //           </div>
  //         </>
  //       ),
  //       unit: <span className="product-unit">$248.66</span>,
  //       quantity: <span className="product-quantity">2</span>,
  //       total: <span className="product-total-price">$943.30</span>,
  //     },
  //   ];

  const invoiceTableColumns = [
    {
      title: "#",
      dataIndex: "row",
      key: "row",
    },
    {
      title: "Product Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Prix Unitaire",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const printInvoice = () => {
    window.print();
  };

  return (
    <div className="invoice-area">
      <Row gutter={15}>
        <Col md={24}>
          <Cards headless>
            <div className="invoice-content-wrap" id="print-invoice-content">
              <InvoiceHeader>
                <Row>
                  <Col sm={12} xs={24}>
                    <figure className="company-logo"></figure>
                  </Col>
                  <Col sm={12} xs={24}>
                    <div>
                      {isExtra === false && (
                        <address className="invoice-info">
                          Boutique <br />
                          {facture?.Store?.storeName} <br />
                          Société de livraison :{" "}
                          {facture?.DeliveryCompanyData?.name} <br />
                          Frais de livraison :{" "}
                          {Number(facture?.deliveryFee)?.toFixed(3)} TND
                        </address>
                      )}
                    </div>
                  </Col>
                </Row>
              </InvoiceHeader>
              <InvoiceLetterBox>
                <div className="invoice-letter-inner">
                  {isExtra === false && (
                    <article className="invoice-author">
                      <Heading className="invoice-author__title" as="h3">
                        Facture {facture?.echange === true && "(ECHANGE)"}
                      </Heading>
                      <p>No : {facture?.commandeRef}</p>
                      <p>Date : {facture?.date}</p>
                    </article>
                  )}
                  {isExtra === true && (
                    <Heading className="invoice-author__title" as="h3">
                      {facture?.ClientExtra?.name} - {facture?.date}
                    </Heading>
                  )}
                  <div className="invoice-barcode"></div>
                  {isExtra === false && (
                    <address className="invoice-customer">
                      <Heading className="invoice-customer__title" as="h5">
                        FACTURE À :
                      </Heading>
                      <p>
                        {facture?.clientName} <br />
                        {facture?.ville}, <br />
                        {facture?.adresse}
                      </p>
                    </address>
                  )}
                </div>
              </InvoiceLetterBox>

              <br />
              <br />
              <ProductTable>
                <div className="table-invoice table-responsive">
                  <Table
                    dataSource={invoiceTableData}
                    columns={invoiceTableColumns}
                    pagination={false}
                  />
                </div>
              </ProductTable>

              <Row justify="end">
                <Col xxl={4} xl={5} sm={8} xs={14} offset={10}>
                  <OrderSummary>
                    <div className="invoice-summary-inner">
                      <ul className="summary-list" style={{ paddingLeft: 0 }}>
                        <li>
                          <span className="summary-list-title">Remise :</span>
                          <span className="summary-list-text">{`${Number(
                            facture?.reduction
                          )?.toFixed(3)} TND`}</span>
                        </li>
                        {isExtra === false && (
                          <li>
                            <span className="summary-list-title">
                              Frais de livraison :
                            </span>
                            <span className="summary-list-text">{`${Number(
                              facture?.deliveryFee
                            )?.toFixed(1)} TND`}</span>
                          </li>
                        )}
                      </ul>
                      <Heading className="summary-total" as="h4">
                        <span className="summary-total-label">Total : </span>
                        <span className="summary-total-amount">{`${Number(
                          facture?.totalAmount
                        )?.toFixed(3)} TND`}</span>
                      </Heading>
                    </div>
                  </OrderSummary>
                </Col>
              </Row>
              <Row justify="end">
                <Col lg={12} md={18} sm={24} offset={0}>
                  <InvoiceAction>
                    <Button
                      size="medium"
                      shape="round"
                      type="default"
                      onClick={() => printInvoice()}
                    >
                      <FeatherIcon icon="printer" size={14} />
                      Print
                    </Button>
                  </InvoiceAction>
                </Col>
              </Row>
            </div>
          </Cards>
        </Col>
      </Row>
    </div>
  );
}

export default Invoice;
