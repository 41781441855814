import React from "react";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { Avatar } from "antd";
import { BsBellFill } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import AvatarImage from "../../assets/img/avatar.png";
import BoutiqueAvatar from "../BoutiqueAvatar/BoutiqueAvatar";
import { InfoWraper, UserDropDwon } from "./auth-info-styles";
import { Popover } from "../Popup/popup";
import Heading from "../heading/HeadingText";
import FeatherIcon from "feather-icons-react";
import LogoSidebar from "../../assets/img/verticallogo.png";
import LogoSidebarBlack from "../../assets/img/blackortyLogo.png";
import { CollapseIcon, NotCollapseIcon } from "../../utils/icons";

const AuthentificatedNavbar = ({
  openSidebar,
  toggleCollapseSidebar,
  collapsedSidebar,
}) => {
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            style={{ width: 25, height: 25, borderRadius: 15 }}
            src={AvatarImage}
            alt=""
          />
          <figcaption>
            <Heading as="h5">
              {userData?.firstName} {userData?.lastName}
            </Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/profile">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <div
          style={{ cursor: "pointer" }}
          className="user-dropdwon__bottomAction"
          onClick={logout}
        >
          <FeatherIcon icon="log-out" /> Déconnexion
        </div>
      </div>
    </UserDropDwon>
  );

  return (
    <header className="staticHeader">
      <div className="d-flex" style={{ width: "100%" }}>
        <div
          className={`sidebar-header ${
            collapsedSidebar
              ? "sidebar-header-collapsed"
              : "sidebar-header-nocollapsed"
          }`}
        >
          <div
            onClick={toggleCollapseSidebar}
            style={{ marginLeft: 25, cursor: "pointer" }}
          >
            {collapsedSidebar === false ? (
              <CollapseIcon />
            ) : (
              <NotCollapseIcon />
            )}
          </div>
          <div className="sidebar-logo" href="/dashboard/">
            <div >
              <img
                style={{ position: "relative" }}
                alt="logo orty"
                src={collapsedSidebar ? LogoSidebarBlack : LogoSidebar}
                width="110"
              />
              <span className="g-ecommerce-txt">GESTION E-COMMERCE</span>
            </div>
          </div>
          <div style={{ width: 50 }} />
        </div>
        <div className="auth-header-container">
          <div className="auth-header-profile">
            <div className="actions-left">
              <button
                onClick={() => {
                  openSidebar();
                }}
                className="authnav-btn-mobile"
                tabIndex="0"
                type="button"
              >
                <svg
                  className="sidebar-tggle-icon"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="MenuIcon"
                >
                  <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"></path>
                </svg>
                <span className="abs-sidsvg"></span>
              </button>
              <BoutiqueAvatar
                name={userData?.email}
                size={28}
                fontSize={8}
                colorText="#80808078"
              />
            </div>
            <div className="actions-right">
              <div className="d-flex">
                <InfoWraper>
                  <div className="nav-author">
                    <Popover
                      placement="bottomRight"
                      content={userContent}
                      action="click"
                    >
                      <Link to="#" className="head-example">
                        <Avatar src={AvatarImage} />
                      </Link>
                    </Popover>
                  </div>
                </InfoWraper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AuthentificatedNavbar;
