import {
  ADD_COMMANDE_SPINNER_DIS,
  ADD_COMMANDE_SPINNER_EN,
  ADD_COMMANDE_SUCCESS,
  ADD_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE,
  CHANGE_COMMANDE_STATUS_SPINNER_DIS,
  CHANGE_COMMANDE_STATUS_SPINNER_EN,
  CHANGE_COMMANDE_STATUS_SUCCESS,
  COMMANDE_STATS,
  COMMANDE_STATS_SPINNER_DIS,
  COMMANDE_STATS_SPINNER_EN,
  DELETE_COMMADNE_SPINNER_DIS,
  DELETE_COMMANDE_SPINNER_EN,
  DELETE_COMMANDE_SUCCESS,
  DELETE_IDS_FROM_COMMANDE,
  DELETE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE,
  GET_COMMANDE_BY_REF_RESET,
  GET_COMMANDE_BY_REF_SPINNER_DIS,
  GET_COMMANDE_BY_REF_SPINNER_EN,
  GET_COMMANDE_BY_REF_SUCCESS,
  GET_COMMANDE_DETAILS_SPINNER_DIS,
  GET_COMMANDE_DETAILS_SPINNER_EN,
  GET_COMMANDE_DETAILS_SUCCESS,
  GET_COMMANDE_SPINNER_DIS,
  GET_COMMANDE_SPINNER_EN,
  GET_COMMANDE_SUCCESS,
  RESET_COMMANDE_TEMP,
  RESET_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE,
  SELECT_COMMANDE_INDEX_TO_DELETE,
  UPDATE_COMMANDE_SPINNER_DIS,
  UPDATE_COMMANDE_SPINNER_EN,
  UPDATE_COMMANDE_STATS,
  UPDATE_COMMANDE_SUCCESS,
  UPDATE_PRODUCT_ON_TMP_COMMANDE,
  UPDATE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE,
} from "../types";
import AramexLogo from "../../assets/aramex.png";
import IntigoLogo from "../../assets/intigo.png";

const initialState = {
  commandeReference: null,
  commandeRefDetailsLoader: false,
  commandes: [],
  updateStatusLoader: false,
  add_spinner: false,
  get_commande_spinner: false,
  del_commande_spinner: false,
  select_idx_to_delete: null,
  details_loader: false,
  details_commande: null,
  edit_spinner: false,
  commande: {
    fullname: "",
    phone: "",
    ville: "",
    adresse: "",
    status: "",
    date: "",
    boutique: "",
    products: [],
    total: 0,
    stats: [],
    stats_loader: false,
  },
};

export default function commandeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMANDE_BY_REF_RESET:
      return {
        ...state,
        commandeReference: null,
      };
    case GET_COMMANDE_BY_REF_SUCCESS:
      return {
        ...state,
        commandeReference: action.payload,
      };
    case GET_COMMANDE_BY_REF_SPINNER_EN:
      return {
        ...state,
        commandeRefDetailsLoader: true,
      };
    case GET_COMMANDE_BY_REF_SPINNER_DIS:
      return {
        ...state,
        commandeRefDetailsLoader: false,
      };
    case COMMANDE_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case UPDATE_COMMANDE_STATS:
      return { ...state };
    case COMMANDE_STATS_SPINNER_EN:
      return {
        ...state,
        stats_loader: true,
      };
    case COMMANDE_STATS_SPINNER_DIS:
      return {
        ...state,
        stats_loader: false,
      };
    case UPDATE_COMMANDE_SPINNER_EN:
      return {
        ...state,
        edit_spinner: true,
      };
    case UPDATE_COMMANDE_SPINNER_DIS:
      return {
        ...state,
        edit_spinner: false,
      };
    case UPDATE_COMMANDE_SUCCESS:
      const { commandeId, commande } = action.payload;
      const updateCommande = state.commandes.map((c) =>
        c.id === commandeId ? commande : c
      );
      return {
        ...state,
        products: updateCommande,
      };
    case GET_COMMANDE_DETAILS_SPINNER_EN:
      return {
        ...state,
        details_loader: true,
      };
    case GET_COMMANDE_DETAILS_SPINNER_DIS:
      return {
        ...state,
        details_loader: false,
      };
    case GET_COMMANDE_DETAILS_SUCCESS:
      return {
        ...state,
        details_commande: action.payload,
      };
    case RESET_COMMANDE_TEMP:
      return {
        ...state,
        commande: {
          fullname: "",
          phone: "",
          ville: "",
          adresse: "",
          status: "",
          date: "",
          boutique: "",
          products: [],
          total: 0,
        },
      };
    case CHANGE_COMMANDE_STATUS_SPINNER_EN:
      return {
        ...state,
        updateStatusLoader: true,
      };
    case CHANGE_COMMANDE_STATUS_SPINNER_DIS:
      return {
        ...state,
        updateStatusLoader: false,
      };
    // case CHANGE_COMMANDE_STATUS_SUCCESS:
    //   const { commandeIds, status } = action.payload;
    //   const updatedCommandes = state.commandes.map((commande) => {
    //     if (commandeIds.includes(commande.id)) {
    //       return {
    //         ...commande,
    //         etat: status,
    //       };
    //     }
    //     return commande;
    //   });

    //   return {
    //     ...state,
    //     commandes: updatedCommandes,
    //   };
    case CHANGE_COMMANDE_STATUS_SUCCESS:
      const { commandeIds } = action.payload;
      const updatedCommandes = state.commandes.filter(
        (commande) => !commandeIds.includes(commande.id)
      );

      return {
        ...state,
        commandes: updatedCommandes,
      };
    case GET_COMMANDE_SPINNER_EN:
      return {
        ...state,
        get_commande_spinner: true,
      };
    case GET_COMMANDE_SPINNER_DIS:
      return {
        ...state,
        get_commande_spinner: false,
      };
    case GET_COMMANDE_SUCCESS:
      return {
        ...state,
        commandes: action.payload,
      };
    case DELETE_COMMANDE_SPINNER_EN:
      return {
        ...state,
        del_commande_spinner: true,
      };
    case DELETE_COMMADNE_SPINNER_DIS:
      return {
        ...state,
        del_commande_spinner: false,
      };
    case SELECT_COMMANDE_INDEX_TO_DELETE:
      return {
        ...state,
        select_idx_to_delete: action?.payload?.id,
      };
    case DELETE_COMMANDE_SUCCESS:
      const { id } = action.payload;
      return {
        ...state,
        commandes: state.commandes.filter((commande) => commande.id !== id),
      };
    case ADD_COMMANDE_SPINNER_EN:
      return {
        ...state,
        add_spinner: true,
      };
    case ADD_COMMANDE_SPINNER_DIS:
      return {
        ...state,
        add_spinner: false,
      };
    case ADD_COMMANDE_SUCCESS:
      const concatenatedCommande = {
        ...state.commande,
        ...action.payload,
      };
      return {
        ...state,
        commandes: [...state.commandes, concatenatedCommande],
      };
    case ADD_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE:
      let tempTotalValue =
        state.commande.total +
        (action?.payload?.hasPromotion === 1
          ? action?.payload?.promotionalPrice
          : action?.payload?.price) *
          action.qte;
      const newCommande = {
        ...state.commande,
        products: [...state.commande.products, action.payload],
        total: tempTotalValue,
      };
      return {
        ...state,
        commande: newCommande,
      };

    case UPDATE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE:
      const updatedProducts = state.commande.products.map((product) => {
        if (product.ref === action.payload.ref) {
          return {
            ...product,
            usedQte: action.qte,
          };
        }
        return product;
      });

      const updatedTotalValue = updatedProducts.reduce((total, product) => {
        const price = product.isPack
          ? product.packPrice
          : product.hasPromotion === 1
          ? product.promotionalPrice
          : product.price;
        return total + (product.isPack ? price : price * product.usedQte);
      }, 0);
      const updatedCommande = {
        ...state.commande,
        products: updatedProducts,
        total: updatedTotalValue,
      };
      return {
        ...state,
        commande: updatedCommande,
      };

    case UPDATE_PRODUCT_ON_TMP_COMMANDE:
      const updateTotal = action.payload.reduce((total, product) => {
        const price = product.isPack
          ? product.packPrice
          : product.hasPromotion === 1
          ? product.promotionalPrice
          : product.price;
        return total + (product.isPack ? price : price * product.usedQte);
      }, 0);

      return {
        ...state,
        commande: {
          ...state.commande,
          products: action.payload,
          total: updateTotal,
        },
      };

    case DELETE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE:
      const deletedProductRef = action.payload.ref;
      const updatedProductsAfterDelete = state.commande.products.filter(
        (product) => product.ref !== deletedProductRef
      );
      const updatedTotalValueAfterDelete = updatedProductsAfterDelete.reduce(
        (total, product) => total + product.price * product.usedQte,
        0
      );
      const updatedCommandeAfterDelete = {
        ...state.commande,
        products: updatedProductsAfterDelete,
        total: updatedTotalValueAfterDelete,
      };
      return {
        ...state,
        commande: updatedCommandeAfterDelete,
      };

    case DELETE_IDS_FROM_COMMANDE:
      const idsToRemove = action.payload;
      const updatecmd = state.commandes.filter(
        (commande) => !idsToRemove.includes(commande.id)
      );
      return {
        ...state,
        commandes: updatecmd,
      };

    case RESET_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE:
      return {
        ...state,
        commande: {
          fullname: "",
          phone: "",
          ville: "",
          adresse: "",
          status: "",
          date: "",
          boutique: "",
          products: [],
          total: 0,
        },
      };
    default:
      return state;
  }
}
