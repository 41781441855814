import React, { useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GET_CLIENT_EXTRA_COMMANDE_RESET } from "../../../redux/types";
import { ResultList, SearchResultWrapper } from "../../BonSortie/styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { PageHeader } from "../../../components/page-headers/page-headers";
import Invoice from "../../Invoice/Invoice";
import { getClientExtraInvoice } from "../../../redux/actions/clientextra.action";
import { useParams } from "react-router-dom";
const BonSortieCommandeClientExtra = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { invoiceCommandeLoader, invoiceCommande } = useSelector(
    (state) => state.client
  );
  useEffect(() => {
    dispatch({
      type: GET_CLIENT_EXTRA_COMMANDE_RESET,
    });
  }, []);

  useEffect(() => {
    if (params?.id) {
      dispatch(getClientExtraInvoice(`${params?.id}`));
    }
  }, [dispatch, params?.id]);

  return (
    <>
      <PageHeader ghost title="Commande Extra Details" />
      <div className="orty-content-sd">
        <div style={{ marginBottom: 20 }} />
        <SearchResultWrapper>
          <Row gutter={25}>
            <Col xs={24}>
              <ResultList>
                {invoiceCommandeLoader ? (
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                ) : invoiceCommande ? (
                  <Invoice isExtra={true} facture={invoiceCommande} />
                ) : (
                  <Cards headless>
                    <div className="result-list-top">
                      <Row>
                        <Col md={13} xs={24}>
                          <p className="search-found-text">
                            Pas de
                            <span className="result-keyword">
                              “Facture”
                            </span>{" "}
                            Trouvée
                          </p>
                        </Col>
                        <Col md={11} xs={24}>
                          <p className="result-limit">
                            Recherchez avec la référence de commande ou colis.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Cards>
                )}
              </ResultList>
            </Col>
          </Row>
        </SearchResultWrapper>
      </div>
    </>
  );
};

export default BonSortieCommandeClientExtra;
