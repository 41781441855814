import {
  ADD_EXTRA_CLIENT_SPINNER_DIS,
  ADD_EXTRA_CLIENT_SPINNER_EN,
  ADD_EXTRA_CLIENT_SUCCESS,
  ADD_SWITCH_ID_TO_LOADER,
  DELETE_EXTRA_CLIENT_SPINNER_DIS,
  DELETE_EXTRA_CLIENT_SPINNER_EN,
  DELETE_EXTRA_CLIENT_SUCCESS,
  GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS,
  GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_EN,
  GET_CLIENT_EXTRA_COMMANDE_INVOICE_SUCCESS,
  GET_CLIENT_EXTRA_COMMANDE_RESET,
  GET_EXTRA_CLIENT_SPINNER_DIS,
  GET_EXTRA_CLIENT_SPINNER_EN,
  GET_EXTRA_CLIENT_SUCCESS,
  REMOVE_SWITCH_ID_FROM_LOADER,
  RESTORE_EXTRA_CLIENT_SPINNER_DIS,
  RESTORE_EXTRA_CLIENT_SPINNER_EN,
  RESTORE_EXTRA_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from "../types";

const initialState = {
  clients: [],
  add_spinner: false,
  get_spinner: false,
  delete_spinner: false,
  switch_clients: [],
  invoiceCommandeLoader: false,
  invoiceCommande: null,
};

const updateClientDeletedStatus = (clients, id, status) => {
  const index = clients.findIndex(
    (client) => client.id?.toString() === id?.toString()
  );
  if (index !== -1) {
    const updatedClients = [...clients];
    updatedClients[index] = {
      ...updatedClients[index],
      deleted: status,
    };
    return updatedClients;
  }
  return clients;
};

export default function clientextraReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_EN:
      return {
        ...state,
        invoiceCommandeLoader: true,
      };
    case GET_CLIENT_EXTRA_COMMANDE_INVOICE_SPINNER_DIS:
      return {
        ...state,
        invoiceCommandeLoader: false,
      };
    case GET_CLIENT_EXTRA_COMMANDE_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceCommande: action.payload,
      };
    case GET_CLIENT_EXTRA_COMMANDE_RESET:
      return {
        ...state,
        invoiceCommande: null,
      };
    case DELETE_EXTRA_CLIENT_SPINNER_EN:
      return {
        ...state,
        delete_spinner: true,
      };
    case DELETE_EXTRA_CLIENT_SPINNER_DIS:
      return {
        ...state,
        delete_spinner: false,
      };
    case RESTORE_EXTRA_CLIENT_SPINNER_EN:
      return {
        ...state,
        delete_spinner: true,
      };
    case RESTORE_EXTRA_CLIENT_SPINNER_DIS:
      return {
        ...state,
        delete_spinner: false,
      };

    case DELETE_EXTRA_CLIENT_SUCCESS:
      const updateClientsAfterDelete = state.clients.map((client) =>
        client.id === action.id ? action.payload : client
      );
      return {
        ...state,
        clients: updateClientsAfterDelete,
      };

    case RESTORE_EXTRA_CLIENT_SUCCESS:
      const updateClientsAfterRestore = state.clients.map((client) =>
        client.id === action.id ? action.payload : client
      );
      return {
        ...state,
        clients: updateClientsAfterRestore,
      };

    case ADD_SWITCH_ID_TO_LOADER:
      if (!state.switch_clients.includes(action.id)) {
        return {
          ...state,
          switch_clients: [...state.switch_clients, action.id],
        };
      }
      return state;

    case REMOVE_SWITCH_ID_FROM_LOADER:
      return {
        ...state,
        switch_clients: state.switch_clients.filter((id) => id !== action.id),
      };

    case UPDATE_CLIENT_SUCCESS:
      const { client, clientSuppId } = action.payload;
      const updateClientSup = state.clients.map((c) =>
        c.id === clientSuppId ? client : c
      );
      return {
        ...state,
        clients: updateClientSup,
      };
    case GET_EXTRA_CLIENT_SPINNER_EN:
      return {
        ...state,
        get_spinner: true,
      };
    case GET_EXTRA_CLIENT_SPINNER_DIS:
      return {
        ...state,
        get_spinner: false,
      };
    case GET_EXTRA_CLIENT_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };
    case ADD_EXTRA_CLIENT_SPINNER_EN:
      return {
        ...state,
        add_spinner: true,
      };
    case ADD_EXTRA_CLIENT_SPINNER_DIS:
      return {
        ...state,
        add_spinner: false,
      };
    case ADD_EXTRA_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };
    default:
      return state;
  }
}
