import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import { ProtectedRoute } from "../utils/ProtectedRoute";
import {
  ARRIVAGE_CREATE_PERM,
  BONSORTIE_PERM_NAME,
  BOUTIQUE_PERM_NAME,
  CHARGE_PERM_NAME,
  CLIENT_SUPPLIMENTAIRE_COMMANDE_PER_NAME,
  CLIENT_SUPPLIMENTAIRE_LIST_PER_NAME,
  COLIS_PERM_NAME,
  COMMANDE_PERM_NAME,
  CREATE_PERM,
  DASHBOARD_PERM_NAME,
  DELIVERY_PERM_NAME,
  EDIT_PERM,
  EQUIPE_PERM_NAME,
  FETCH_PERM,
  FOURNISSEUR_PERM_NAME,
  OFFRE_PERM_NAME,
  PAIEMENT_EFFECTED_PER_NAME,
  PAIEMENT_RECU_PERM_NAME,
  SPONSORING_PERM_NAME,
  STATISTIC_PERM_NAME,
  STOCK_PERM_NAME,
} from "../utils/permissionsCommun";
import ThemeLayout from "../layout/ThemeLayout";
import Profile from "../screens/Profile/Profile";
import BonSortieCommandeClientExtra from "../screens/ClientExtra/ExtraCommande/BonSortieCommandeClientExtra";

// screens ..
const CreateUser = lazy(() => import("../screens/User/CreateUser/CreateUser"));
const EditUser = lazy(() => import("../screens/User/EditUser/EditUser"));
const Dashboard = lazy(() => import("../screens/Dashboard"));
const Page404 = lazy(() => import("../screens/Page404"));
const AddCommande = lazy(() => import("../screens/Commande/AddCommande"));
const EditCommande = lazy(() => import("../screens/Commande/EditCommande"));
const AddProduct = lazy(() => import("../screens/Stock/Product/AddProduct"));
const AddCommandeClientExtra = lazy(() =>
  import("../screens/ClientExtra/AddCommandeClientExtra")
);
const CommandeClientExtra = lazy(() =>
  import("../screens/ClientExtra/CommandeClientExtra")
);
const EditProduct = lazy(() => import("../screens/Stock/Product/EditProduct"));
const ProductArrivage = lazy(() =>
  import("../screens/Stock/Arrivage/ProductArrivage")
);
const CommandeClient = lazy(() => import("../screens/Commande/CommandeClient"));
const ClientExtraListing = lazy(() =>
  import("../screens/ClientExtra/ClientExtraListing")
);
const Colis = lazy(() => import("../screens/Colis/ColisClient"));
const Stock = lazy(() => import("../screens/Stock"));
const BonSortie = lazy(() => import("../screens/BonSortie/BonSortie"));
const StatistiqueGain = lazy(() => import("../screens/StatistiqueGain"));
const SocieteLivraison = lazy(() => import("../screens/SocieteLivraison"));
const Fournisseur = lazy(() => import("../screens/Fournisseur"));
const Equipe = lazy(() => import("../screens/Equipe"));
const Boutique = lazy(() => import("../screens/Boutique"));
const Offres = lazy(() => import("../screens/Offres"));
const Achats = lazy(() => import("../screens/Achat"));
const PaymentEffectuer = lazy(() => import("../screens/PaymentEffectuer"));
const PaymentRecu = lazy(() => import("../screens/PaymentRecu"));
const Sponsoring = lazy(() => import("../screens/Sponsoring"));
const CommandeInvoiceDetails = lazy(() =>
  import("../screens/Commande/CommandeInvoiceDetails")
);
const AdminRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route
          element={
            <ProtectedRoute name={EQUIPE_PERM_NAME} perm={CREATE_PERM} />
          }
        >
          <Route path="/create-user" element={<CreateUser />} />
        </Route>
        <Route
          element={<ProtectedRoute name={EQUIPE_PERM_NAME} perm={EDIT_PERM} />}
        >
          <Route path="/edit-user/:userId" element={<EditUser />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={DASHBOARD_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route
          element={
            <ProtectedRoute name={DASHBOARD_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/profile/*" element={<Profile />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/404" element={<Page404 />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={COMMANDE_PERM_NAME} perm={CREATE_PERM} />
          }
        >
          <Route path="/add-commande" element={<AddCommande />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={COMMANDE_PERM_NAME} perm={EDIT_PERM} />
          }
        >
          <Route path="/edit-commande/:commandeId" element={<EditCommande />} />
        </Route>
        <Route
          element={<ProtectedRoute name={STOCK_PERM_NAME} perm={CREATE_PERM} />}
        >
          <Route path="/add-product" element={<AddProduct />} />
        </Route>
        <Route
          element={<ProtectedRoute name={STOCK_PERM_NAME} perm={CREATE_PERM} />}
        >
          <Route path="/import" element={<AddProduct />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              name={CLIENT_SUPPLIMENTAIRE_COMMANDE_PER_NAME}
              perm={FETCH_PERM}
            />
          }
        >
          <Route
            path="/clientExtra-commandes"
            element={<CommandeClientExtra />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute
              name={CLIENT_SUPPLIMENTAIRE_COMMANDE_PER_NAME}
              perm={FETCH_PERM}
            />
          }
        >
          <Route
            path="/invoice-extra/:id"
            element={<BonSortieCommandeClientExtra />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute
              name={CLIENT_SUPPLIMENTAIRE_COMMANDE_PER_NAME}
              perm={CREATE_PERM}
            />
          }
        >
          <Route
            path="/add-client-commande"
            element={<AddCommandeClientExtra />}
          />
        </Route>
        <Route
          element={<ProtectedRoute name={STOCK_PERM_NAME} perm={EDIT_PERM} />}
        >
          <Route path="/edit-product/:productId" element={<EditProduct />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              name={STOCK_PERM_NAME}
              perm={ARRIVAGE_CREATE_PERM}
            />
          }
        >
          <Route
            path="/arrivage-product/:productId"
            element={<ProductArrivage />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute name={COMMANDE_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/commande-client/*" element={<CommandeClient />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              name={CLIENT_SUPPLIMENTAIRE_LIST_PER_NAME}
              perm={FETCH_PERM}
            />
          }
        >
          <Route path="/extra-clients" element={<ClientExtraListing />} />
        </Route>
        <Route
          element={<ProtectedRoute name={COLIS_PERM_NAME} perm={FETCH_PERM} />}
        >
          <Route path="/colis/*" element={<Colis />} />
        </Route>
        <Route
          element={<ProtectedRoute name={STOCK_PERM_NAME} perm={FETCH_PERM} />}
        >
          <Route path="/stock" element={<Stock />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={BONSORTIE_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/bon-de-sortie" element={<BonSortie />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={STATISTIC_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/statistics-earnings" element={<StatistiqueGain />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={DELIVERY_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/societes-de-livraison" element={<SocieteLivraison />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={FOURNISSEUR_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/fournisseur" element={<Fournisseur />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={STATISTIC_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/statistique-et-gain" element={<StatistiqueGain />} />
        </Route>
        <Route
          element={<ProtectedRoute name={EQUIPE_PERM_NAME} perm={FETCH_PERM} />}
        >
          <Route path="/equipe" element={<Equipe />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={BOUTIQUE_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/boutiques" element={<Boutique />} />
        </Route>
        <Route
          element={<ProtectedRoute name={OFFRE_PERM_NAME} perm={FETCH_PERM} />}
        >
          <Route path="/offres" element={<Offres />} />
        </Route>
        <Route
          element={<ProtectedRoute name={CHARGE_PERM_NAME} perm={FETCH_PERM} />}
        >
          <Route path="/achat" element={<Achats />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={SPONSORING_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/sponsoring" element={<Sponsoring />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={COMMANDE_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route
            path="/invoice/:commandeRef"
            element={<CommandeInvoiceDetails />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute
              name={PAIEMENT_EFFECTED_PER_NAME}
              perm={FETCH_PERM}
            />
          }
        >
          <Route path="/paiement-effectues" element={<PaymentEffectuer />} />
        </Route>
        <Route
          element={
            <ProtectedRoute name={PAIEMENT_RECU_PERM_NAME} perm={FETCH_PERM} />
          }
        >
          <Route path="/paiement-recus" element={<PaymentRecu />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default ThemeLayout(AdminRoutes);
